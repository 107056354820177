'use client';

import { useEffect } from 'react';
import { usePathname, useRouter } from 'next/navigation';

//* Config
import config from '@/helpers/_config';

//* Components
import Page from '@/components/global/Page';
import Container from '@/components/global/Container';
import Text from '@/components/global/Text';
import Button from '@/components/global/Button';

//* Style
import Custom404Style from '@/styles/pages/Custom404Style';
import { withLanguageContext } from '@/context';

const NotFound = ({ translate }) => {
	//! Next Navigation
	const router = useRouter();
	const pathname = usePathname();

	useEffect(() => {
		if (pathname !== '/not-found/') {
			router.push(`/not-found/`);
		}
	}, [router, pathname]);

	return (
		<Page className={`not-found`}>
			<Custom404Style>
				<Container
					row
					className={'error-container justify-center'}>
					<div className={`col-12 text-center`}>
						<Text
							tag={`h1`}
							className={`white font-zen-dots-regular`}
							text={'error404'}
						/>
					</div>
					<div className={`col-12 text-center`}>
						<Text
							className={'p p1 white font-golos-text-regular text-center mT40'}
							text={'errorTitle'}
						/>
					</div>
					<div className={`col-12 text-center`}>
						<Text
							className={'p p1 white font-golos-text-regular text-center'}
							text={'errorDescription'}
						/>
					</div>
					<div className={`col-12 mT40 button-section`}>
						<Button
							type={'submit'}
							text={translate('goToHomepage')}
							url={config.routes.home.path}
							className={'primary uppercase'}
						/>
					</div>
				</Container>
			</Custom404Style>
		</Page>
	);
};
export default withLanguageContext(NotFound, ['translate']);
