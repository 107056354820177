import styled from 'styled-components';

const Custom404Style = styled.section`
	height: calc(100dvh - var(--headerFixedHeight) * 2);

	.error-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;

		.row {
			margin-top: -(var(--headerFixedHeight));
		}
	}

	.button-section {
		display: flex;
		justify-content: center;
	}

	//! 1920
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
	}

	//! 1536
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
	}

	//! 1366
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
	}

	//! 1280
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
	}

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
	}
`;

export default Custom404Style;
